/* You can add global styles to this file, and also import other style files */
html, body {
    padding: 0;
    margin: 0;
    background: #ffffff;
    font-family: "OpenSans-Regular";
}
@font-face {
    font-family: 'OpenSans-Regular';
    src: url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
  }
@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
}